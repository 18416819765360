/**
 * Nuxt.js plugin for initializing and injecting API factories into the application context.
 *
 * @param {Object} context - The Nuxt.js context object.
 * @param {Function} inject - The inject function provided by Nuxt.js to inject properties into the context.
 *
 * @description
 * This Nuxt.js plugin initializes API factories and injects them into the Nuxt.js context.
 * The API factories are created using modules such as 'List' and are associated with specific
 * functionalities. The injected factories are then accessible throughout the application using
 * the "$api" property, providing a convenient way to interact with different API services.
 *
 * @example
 * / Using the injected API factory in a Vue component
 * export default {
 *   async asyncData({ $api }) {
 *     / Accessing the 'list' API factory to fetch data
 *     const listData = await $api.list.getData();
 *     return { listData };
 *   },
 *   / Other component options
 * }
 */
import List from "@/api/api-list";

export default (context, inject) => {
  // Initialize API factories
  const factories = {
    list: List(context.$axios),
    // Add more API factories as needed
  };

  // Inject $api
  inject("api", factories);
};
