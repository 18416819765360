/**
 * Vuex state object for managing global application state.
 *
 * @typedef {Object} GlobalState
 * @property {string} CDN_URL - The base URL for the Content Delivery Network (CDN).
 * @property {string} BASE_URL - The base URL of the application.
 * @property {string} API_URL - The base URL for making API requests.
 * @property {string} lang - The current language setting for the application.
 */

/**
 * Vuex state module representing the global state of the application.
 *
 * @type {GlobalState}
 *
 * @description
 * This Vuex state module manages global state variables for the application.
 * It includes properties such as CDN_URL, BASE_URL, API_URL, and lang, which
 * store configuration settings and the current language setting. These values
 * are initialized with environment variables but can be modified during runtime
 * as needed. The state is accessible across components through Vuex.
 *
 * @example
 * / Accessing state properties in a component
 * export default {
 *   computed: {
 *     cdnUrl() {
 *       return this.$store.state.CDN_URL;
 *     },
 *     currentLang() {
 *       return this.$store.state.lang;
 *     },
 *   },
 *   / Other component options
 * }
 */
export const state = () => ({
  CDN_URL: process.env.CDN_URL,
  BASE_URL: process.env.BASE_URL,
  API_URL: process.env.API_URL,
  lang: '',
});
