let URL = process.env.API_URL;
if (process.browser) {
  if(window.localStorage.getItem("lang")){
    var lang = window.localStorage.getItem("lang")
  }else{
    var lang = "tr"
    window.localStorage.setItem("lang", lang)
  }
}
export default axios => ({
  topMenu() {
    return axios.get(URL + 'top-menu' + '?_locale=' + lang);
  },
  
  footerMenu() {
    return axios.get(URL + 'footer-menu');
  },
  
  homePage() {
    return axios.get(URL + 'home' + '?_locale=' + lang);
  },
  
  aboutUs() {
    return axios.get(URL + 'about-us' + '?_locale=' + lang);
  },
  
  ourValues() {
    return axios.get(URL + 'our-values' + '?_locale=' + lang);
  },
  
  productsByNeeds() {
    return axios.get(URL + 'products-by-needs' + '?_locale=' + lang);
  },
  
  products() {
    return axios.get(URL + 'products' + '?_locale=' + lang);
  },
  
  companies() {
    return axios.get(URL + 'companies' + '?_sort=id' + '&_locale=' + lang);
  },
  
  investorRelations() {
    return axios.get(URL + 'investor-relations' + '?_locale=' + lang);
  },
  
  career() {
    return axios.get(URL + 'career' + '?_locale=' + lang);
  },
  
  boardMembers(data) {
    return axios.get(URL + 'board-members' + data);
  },
  
  financialReports() {
    return axios.get(URL + 'financial-reports' + '?_locale=' + lang);
  },
  
  announcements() {
    return axios.get(URL + 'announcements' + '?_locale=' + lang);
  },
  
  sustainibilityReports() {
    return axios.get(URL + 'sustainibility-reports' + '?_locale=' + lang);
  },
  
  solutions() {
    return axios.get(URL + 'solutions' + '?_locale=' + lang);
  },

  setSeo(data){
    let metaData = {
      title: data.title,
      description: data.description,
      keywords: data.keywords,
      shareImage: data.shareImage,
      canonical: data.canonical,
    }
    return metaData;
  },

  setLangSlug(path){
    let cleanPath = path.split("/");
    cleanPath.shift();
    if(cleanPath[0] == "en"){
      return path.replace('/en', '');
    }else{
      return path
    }
  },

  getLangFromPath(path){
    return path.split('/').slice(1)[0]
  },


  getPathWithOutLang(path){
    return `/${path.split("/").filter(item=>item.length>0).splice(1).join('/')}`;
  }
});